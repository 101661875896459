<template>
  <div>
    <b-row>
      <b-col sm="12">
        <!-- <h2>
          {{ project.name }}
        </h2> -->
        <h1>{{ $t('expenses') }}</h1>
      </b-col>
      <b-col sm="12">
        <b-row class="justify-content-center">
          <b-col
            sm="6"
            lg="3"
          >
            <income-total-card
              :label="'additivesAndChanges'"
              :total-income="totalAdditives"
            />
          </b-col>
          <b-col
            sm="6"
            lg="3"
          >
            <income-total-card
              :label="'directCost'"
              :total-income="directCost"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="justify-content-center">
      <apexchart
        width="500"
        type="pie"
        :options="options"
        :series="series"
      />
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IncomeTotalCard from '@/components/income/IncomeTotalCard.vue'

export default {
  components: {
    IncomeTotalCard,
  },
  data() {
    return {
      options: {
        chart: {
          id: 'vuechart-example',
        },
        labels: [this.$t('additivesAndChanges'), this.$t('directCost')],
        colors: ['#bb81fc', '#6e32b4'],
      },
    }
  },
  computed: {
    ...mapGetters('projects', ['project']),
    totalAdditives() {
      return `$${this.$options.filters.money(this.project.additives_and_changes_expenses_total)}`
    },
    directCost() {
      return `$${this.$options.filters.money(this.project.direct_cost_expenses_total)}`
    },
    series() {
      return [
        parseFloat(this.project.additives_and_changes_expenses_total),
        parseFloat(this.project.direct_cost_expenses_total),
      ]
    },
  },
  created() {
    this.fetchProject(this.$route.params.projectId)
  },
  methods: {
    ...mapActions('projects', ['fetchProject']),
  },
}
</script>

<style lang="scss" scoped></style>
